import erc20Abi from './abis/erc20.json'
import gameAbi from './abis/game.json'
import marketplaceAbi from './abis/marketplace.json'
import footballPlayersAbi from './abis/footballPlayer.json'
import footballHeroesStorage from './abis/footballHeroesStorage.json'
import presaleAbi from './abis/presale.json'
import demoAbi from './abis/demo.json'

const abis = {
	erc20: erc20Abi,
	marketplace: marketplaceAbi,
	game: gameAbi,
	footballPlayer: footballPlayersAbi,
	footballHeroesStorage: footballHeroesStorage,
	presale: presaleAbi,
	demo: demoAbi,
}

export default abis
