// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.
const addresses = {
	BUSDTestnet: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7',
	BUSD: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
	GBTOKEN: '0x4b900990729e30261c3102b0557fd34219d90366',
	Marketplace: '0xaBb5F8d120fAeddc546494F59fE9085D3ac0b0C4',
	Game: '0x496Bc0b17D3f78B2dB5d7e2375202448071F5d2c',
	FootballPlayers: '0x71C9013F9EdE139fFB9F3A80c5164B0Fdceb2115',
	FootballHeroesStorage: '0x4aD98cFbA873e475D69Fea4093Ad354a0E8D1C3C',
	presale: '0xd4374a73F53C8D0a7f68404665BDdDA97150d39C',
	demo: '0x451B71A6d32b0cd6e8589adC2ac30BAcbc791E17',
}
/*

[0,"0",3, 4],[[0,"1",3, 4],[1,"1",3,4],[2,"1",3,4],[3,"1",3,4]],[[0,"2",3,4],[1,"2",3,4],[2,"2",3,4],[3,"2",3,4]],[[0,"3",3,4],[1,"3",3,4]],"100","100"
[0,"0",2, 3],[[0,"1",2, 3],[1,"1",2,3],[2,"1",2,3],[3,"1",2,3],[4,"1",2,3]],[[0,"2",2,3],[1,"2",2,3],[2,"2",2,3]],[[0,"3",2,3],[1,"3",2,3]],"80","80"
[0,"0",1, 2],[[0,"1",1, 2],[1,"1",1,2],[2,"1",1,2],[3,"1",1,2]],[[0,"2",1,2],[1,"2",1,2],[2,"2",1,2]],[[0,"3",1,2],[1,"3",1,2],[2,"3",1,2]],"60","60"
[0,"0",0, 1],[[0,"1",0, 1],[1,"1",0,1],[2,"1",0,1],[3,"1",0,1]],[[0,"2",0,1],[1,"2",0,1],[2,"2",0,1],[3,"2",0,1],[4,"2",0,1]],[[0,"3",0,1]],"40","40"
0x1ab1cec14dc4831decf8b774dce47242bed3b8d0
 */
export default addresses
